import React from 'react';
import styled from 'styled-components';
import Heading from './typography/Heading';
import Text from './typography/Text';
import ComponentWrapper from './ComponentWrapper';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  z-index: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 940px;
  background: white;
  padding: 1.5rem;
  margin: -3rem 2rem 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 3.5rem;
    margin: -7rem 2rem 0;
  }
`;

const Content = styled.div`
  max-width: 63rem;
  margin: 0 auto;
  text-align: center;
`;

const Intro = ({ title, text }) => {
  return (
    <ComponentWrapper>
      <Wrapper>
        <ContentWrapper>
          <Content>
            <Heading>{title}</Heading>
            <Text big>{text}</Text>
          </Content>
        </ContentWrapper>
      </Wrapper>
    </ComponentWrapper>
  );
};

export default Intro;
