import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { transparentize } from 'polished';
import SVG from 'react-inlinesvg';
import Arrow from '../../static/long-arrow.svg';

const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ white, green, theme }) =>
    white
      ? 'white'
      : green
      ? ({ theme }) => theme.primaryColor
      : transparentize(0.2, theme.textColor)};
  margin: 2rem 0 0;
  text-transform: uppercase;
  font-weight: 500;

  &:hover {
    > span {
      transform: translateX(1rem);
    }
  }

  > span {
    display: inline-block;
    margin: ${({ back }) => (back ? '0 1rem 0 0' : '0 0 0 1rem')};
    transform: ${({ back }) => back && 'scaleX(-1)'};
    transition: transform 0.25s;

    * {
      stroke: ${({ white, theme, green }) =>
        white
          ? 'white'
          : green
          ? ({ theme }) => theme.primaryColor
          : theme.textColor};
      fill: ${({ white, theme, green }) =>
        white
          ? 'white'
          : green
          ? ({ theme }) => theme.primaryColor
          : theme.textColor};
    }
  }
`;

const CustomLink = ({ to, children, white, green, back }) => {
  return (
    <StyledLink back={back} green={green} white={white} to={to}>
      {back ? (
        <>
          <SVG src={Arrow} />
          {children}
        </>
      ) : (
        <>
          {children}
          <SVG src={Arrow} />
        </>
      )}
    </StyledLink>
  );
};

export default CustomLink;
