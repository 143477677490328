import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import Container from './Container';

const StyledContainer = styled(Container)`
  @media (${({ theme }) => theme.respondTo.mobile}) {
    .gatsby-image-wrapper {
      height: 20rem;
    }
  }
`;

const Hero = ({ img }) => {
  return (
    <StyledContainer big>
      <Img fluid={img} />
    </StyledContainer>
  );
};

export default Hero;
