import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import ScrollAnimation from 'react-animate-on-scroll';

import ArrowLink from '../components/Link';
import Layout from '../components/Layout';
import Container from '../components/Container';
import SEO from '../components/Seo';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import ComponentWrapper from '../components/ComponentWrapper';
import Heading from '../components/typography/Heading';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 94rem;
  > * {
    &:not(:last-of-type) {
      border-bottom: 1px solid #efefef;
    }
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.primaryColor};
  margin: 0 0 0.5rem;
`;

const ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
`;

const ImgWrapper = styled.div`
  width: 100%;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    width: 25rem;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  margin: 2rem 0 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 0 0 0 2rem;
  }
`;

const projecten = ({ data }) => {
  const {
    hero_title,
    hero_img,
    hero_introductie,
  } = data.allPrismicPage.edges[0].node.data;

  const { edges: projects } = data.allPrismicProjecten;

  return (
    <Layout>
      <SEO title="Projecten" />
      <Hero img={hero_img.localFile.childImageSharp.fluid} />
      <Container>
        <Intro title={hero_title.text} text={hero_introductie.text} />
        <ComponentWrapper>
          <List>
            {projects.map((project, i) => {
              const { title, text, img } = project.node.data;
              const { uid } = project.node;
              return (
                <ScrollAnimation key={uid} animateIn="fadeInUp" animateOnce>
                  <ListItem>
                    <ImgWrapper>
                      <Link to={`/projecten/${uid}`}>
                        <Img fluid={img.localFile.childImageSharp.fluid} />
                      </Link>
                    </ImgWrapper>
                    <ContentWrapper>
                      <Link to={`/projecten/${uid}`}>
                        <StyledHeading as="h3">{title.text}</StyledHeading>
                      </Link>
                      <p>{text.text.slice(0, 200) + '...'}</p>
                      <ArrowLink withArrow to={`/projecten/${uid}`}>
                        Lees meer
                      </ArrowLink>
                    </ContentWrapper>
                  </ListItem>
                </ScrollAnimation>
              );
            })}
          </List>
        </ComponentWrapper>
      </Container>
    </Layout>
  );
};

export default projecten;

export const pageQuery = graphql`
  query projecten {
    allPrismicPage(filter: { uid: { eq: "projecten" } }) {
      edges {
        node {
          id
          data {
            hero_title {
              text
            }
            hero_introductie {
              text
            }
            hero_img {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 80, maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicProjecten {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            text {
              text
            }
            category {
              text
            }
            img {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
